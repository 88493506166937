export const routingPaths = {
  home: "/legacy",
  login: "/login",
  product: "/product/:sessionId",
  snapshot: "/snapshot/:snapshotId",
  productCompare: "/product/compare/:productId",
  privacyPolicy: "/privacy",
  terms: "/terms",
  suggestions: "/suggestions",
  notFound: "/404",
  chatview: "/chat/:sessionId",
  chatviewNoSession: "/chat",
  baseview: "/",
};

/* eslint-disable-next-line @typescript-eslint/no-empty-function */
export const noop = () => {};

export const headerTransformAnchor = 370;
