import {
  GetBuyingOptionResponse,
  GetReviewsResponse,
  GetSepecificationsResponse,
  ProductBuyingOption,
  GetLikedOverviewReponseV2,
} from "models/services/api";
import React, { useEffect, useState } from "react";
import {
  getLikedBuyingOptionsV2,
  getLikedOverviewV2,
  getLikedReviewsV2,
  getLikedSpecificationsV2,
} from "services/product";
import styled from "styled-components";
import { Product } from "../models";
import {
  CommonSectionInfo,
  ProductBuyingOptionInfo,
} from "models/pages/products";
import { replaceNewline, restoreNewline } from "utils/appUtils";
import ProductSection from "./ProductSection";
import SpecificationSection from "./SpecificationSection";
import ReviewSection from "./ReviewSection";
import BuyingOptionSection from "./BuyingOptionSection";
import PreviewSection from "./PreviewSection";
import { OverviewSection } from "./OverviewSection";
import usePolling from "hooks/polling";
import CompactHeader from "./CompactHeader";
import EmptyListPlaceHolder from "components/icon/EmptyListPlaceHolder";

interface Props {
  sessionId: string;
  favorites: Product[];
}

export const Favorites: React.FC<Props> = ({ sessionId, favorites }) => {
  const [reviews, setReviews] = useState<CommonSectionInfo[]>([]);
  const [specifications, setSpecifications] = useState<CommonSectionInfo[]>([]);
  const [productBuyingOptions, setProductBuyingOptions] = useState<
    ProductBuyingOptionInfo[]
  >([]);
  const [shouldShowHeader, setShouldShowHeader] = useState<boolean>(false);
  const [isLoadingReviews, setIsLoadingReviews] = useState<boolean>(true);
  const [isLoadingSpecifications, setIsLoadingSpecifications] =
    useState<boolean>(true);
  const [isLoadingProductBuyingOptions, setIsLoadingProductBuyingOptions] =
    useState<boolean>(true);

  const [overviewResponse, setOverviewResponse] = useState<
    GetLikedOverviewReponseV2 | undefined
  >(undefined);
  const [shouldPollReviews, setShouldPollReviews] = useState<boolean>(false);
  const { data: reviewResponse } = usePolling<GetReviewsResponse>(
    getLikedReviewsV2,
    shouldPollReviews,
    [sessionId],
    5000
  );

  const [shouldPollSpecifications, setShouldPollSpecifications] =
    useState<boolean>(false);
  const { data: specificationsResponse } =
    usePolling<GetSepecificationsResponse>(
      getLikedSpecificationsV2,
      shouldPollSpecifications,
      [sessionId],
      5000
    );

  const [shouldPollBuyingOptions, setShouldPollBuyingOptions] =
    useState<boolean>(false);
  const { data: buyingOptionsResponse } = usePolling<GetBuyingOptionResponse>(
    getLikedBuyingOptionsV2,
    shouldPollBuyingOptions,
    [sessionId],
    5000
  );

  const handleScroll = (e: Event) => {
    if ((e.target as HTMLDivElement).scrollTop > 255) {
      setShouldShowHeader(true);
    } else {
      setShouldShowHeader(false);
    }
  };

  useEffect(() => {
    setShouldPollReviews(true);
    setShouldPollSpecifications(true);
    setShouldPollBuyingOptions(true);

    getLikedOverviewV2(sessionId).then((res) => {
      setOverviewResponse(res);
    });

    // hacky..
    document
      .getElementById("details-wrapper")!
      .addEventListener("scroll", handleScroll);
    return () => {
      const target = document.getElementById("details-wrapper");
      if (target) {
        target.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (reviewResponse && favorites.length > 0) {
      setReviews(
        favorites.map((favorite) => {
          const review = reviewResponse[favorite.id];

          if (review) {
            const parsedReview = JSON.parse(replaceNewline(review));
            return {
              highlights: parsedReview.highlights
                ? parsedReview.highlights
                : [],
              content: parsedReview.textcontent
                ? restoreNewline(parsedReview.textcontent)
                : "",
              type: "ratingsandreviews",
              isLoading: parsedReview ? false : true,
              productId: favorite.id,
            };
          } else {
            return {
              highlights: [],
              content: "",
              type: "ratingsandreviews",
              isLoading: true,
              productId: favorite.id,
            };
          }
        })
      );
      setIsLoadingReviews(false);

      if (
        favorites.every((favorite) =>
          Object.keys(reviewResponse).includes(favorite.id)
        )
      ) {
        setShouldPollReviews(false);
      }
    }
  }, [reviewResponse, favorites]);

  useEffect(() => {
    if (specificationsResponse && favorites.length > 0) {
      setSpecifications(
        favorites.map((favorite) => {
          const content = specificationsResponse[favorite.id];
          return {
            isLoading: content ? false : true,
            content: content
              ? restoreNewline(JSON.parse(replaceNewline(content)).textcontent)
              : "",
            highlights: [],
            productId: favorite.id,
            type: "specifications",
          };
        })
      );
      setIsLoadingSpecifications(false);

      if (
        favorites.every((favorite) =>
          Object.keys(specificationsResponse).includes(favorite.id)
        )
      ) {
        setShouldPollSpecifications(false);
      }
    }
  }, [specificationsResponse, favorites]);

  useEffect(() => {
    if (buyingOptionsResponse && favorites.length > 0) {
      setProductBuyingOptions(
        favorites.map((favorite) => {
          const pbo: ProductBuyingOption = Object.values(
            buyingOptionsResponse
          ).find((p) => p.product_id === favorite.id);
          if (pbo) {
            return {
              productId: pbo.product_id,
              isLoading: false,
              buyingOptions: pbo.buying_options.map((buying_option) => ({
                platformName: buying_option.platform_name,
                delivery: buying_option.delivery,
                price: buying_option.price,
                link: buying_option.link,
              })),
            };
          } else {
            return {
              productId: favorite.id,
              isLoading: true,
              buyingOptions: [],
            };
          }
        })
      );
      setIsLoadingProductBuyingOptions(false);

      if (
        favorites.every(
          (favorite) =>
            Object.values(buyingOptionsResponse).find(
              (b) => b.product_id === favorite.id
            ) &&
            Object.values(buyingOptionsResponse).find(
              (b) => b.product_id === favorite.id
            ).buying_options.length > 0
        )
      ) {
        setShouldPollBuyingOptions(false);
      }
    }
  }, [buyingOptionsResponse, favorites]);

  return (
    <StyledWrapper>
      <HomeWrapper>
        {shouldShowHeader && <CompactHeader thumbnails={favorites} />}
        <EmptyList visible={favorites.length === 0}>
          <EmptyListPlaceHolder />
          <EmptyListText>
            You have no favorite products yet. Start adding some!
          </EmptyListText>
        </EmptyList>
        <DetailsWrapper id="details-wrapper" visible={favorites.length !== 0}>
          <PreviewSection products={favorites} isLoading={false} />
          <ProductSection
            title="Overview"
            size={1}
            sessionId={sessionId}
            sectionName={"Overview"}
            id={"overview"}
          >
            <OverviewSection overviewResponse={overviewResponse} />
          </ProductSection>

          <ProductSection
            title="Buying Options"
            size={favorites.length}
            sessionId={sessionId}
            sectionName={"Buying Options"}
            id={"buyingoptions"}
          >
            <BuyingOptionSection
              isLoadingThumbnail={isLoadingProductBuyingOptions}
              productBuyingOptions={productBuyingOptions}
              products={favorites}
            />
          </ProductSection>
          <ProductSection
            title="Specifications"
            size={specifications.length}
            sessionId={sessionId}
            sectionName={"Specifications"}
            id={"specifications"}
          >
            <SpecificationSection
              isLoadingThumbnail={isLoadingSpecifications}
              specifications={specifications}
              products={favorites}
            />
          </ProductSection>
          <ProductSection
            title="Ratings and Reviews"
            size={reviews.length}
            sessionId={sessionId}
            sectionName={"Ratings & Reviews"}
            id={"ratingsandreviews"}
          >
            <ReviewSection
              isLoadingThumbnail={isLoadingReviews}
              reviews={reviews}
              products={favorites}
            />
          </ProductSection>
        </DetailsWrapper>
      </HomeWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  background: #f7f6f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  h3,
  h4,
  ul,
  p,
  ol {
    margin: 6px;
  }
`;

const HomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #f7f6f9;
`;

interface VisibilityProps extends React.AllHTMLAttributes<HTMLDivElement> {
  visible: boolean;
}

const DetailsWrapper = styled.div<VisibilityProps>`
  width: 100%;
  overflow: auto;
  background: #f7f6f9;
  padding: 24px 18px;
  height: 100%;

  ${({ visible }) => (visible ? `display: block;` : `display: none;`)}
`;

const EmptyList = styled.div<VisibilityProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  align-self: stretch;
  justify-self: anchor-center;
  position: absolute;
  top: 33%;
  width: 300px;

  ${({ visible }) => (visible ? `display: flex;` : `display: none;`)}
`;

const EmptyListText = styled.div`
  color: var(--black, #0d0319);
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
`;
