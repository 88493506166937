import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";

import { createSession } from "services/user";
import { BaseLayout } from "components/baseLayout/BaseLayout";
import Prompt from "./promptV2/Prompt";
import { ProductPanel } from "./productPanel/ProductPanel";
import { AppContext } from "context/AppContext";
import { Product } from "./models";

export const ChatPage: React.FC = () => {
  const { sessionId } = useParams<{ sessionId: string }>();
  const [currentSessionId, setCurrentSessionId] = useState<string | undefined>(
    undefined
  );
  const history = useHistory();
  const {
    state: { userId },
    dispatch,
  } = useContext(AppContext);
  const [isFloatingPanelVisible, setIsFloatingPanelVisible] =
    useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(
    undefined
  );

  useEffect(() => {
    if (sessionId) {
      setCurrentSessionId(sessionId);
    } else {
      if (userId) {
        createSession(userId).then((res) => {
          setCurrentSessionId(res.id);
          history.push({ pathname: `${window.location.pathname}/${res.id}` });
          dispatch({ type: "ADD_SESSION_ID", payload: res.id });
        });
      }
    }
  }, []);

  const onProductClick = (product: Product) => {
    setIsFloatingPanelVisible(true);
    setSelectedProduct(product);
  };

  const onClickOverlay = () => {
    setIsFloatingPanelVisible(false);
  };

  useEffect(() => {
    if (isFloatingPanelVisible) {
      const body = document.getElementById("floating-component-wrapper");
      body?.scrollTo({
        top: 0,
      });
    }
  }, [isFloatingPanelVisible]);

  return (
    <BaseLayout
      isFloatingComponentVisible={isFloatingPanelVisible}
      onClickOverlay={onClickOverlay}
      mainComponent={
        <MainComponentWrapper>
          <ContentWrapper>
            {userId && currentSessionId && (
              <Prompt
                onProductClick={onProductClick}
                userId={userId}
                sessionId={currentSessionId}
              />
            )}
          </ContentWrapper>
        </MainComponentWrapper>
      }
      floatingComponent={
        <FloatingComponentWrapper id="floating-component-wrapper">
          {!!currentSessionId && !!selectedProduct && (
            <ProductPanel
              sessionId={currentSessionId}
              selectedProduct={selectedProduct}
            ></ProductPanel>
          )}
        </FloatingComponentWrapper>
      }
    ></BaseLayout>
  );
};

const MainComponentWrapper = styled.div`
  height: 100%;
  width: 100vw;
  background: var(--purple-gray10, #f7f6f9);
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const FloatingComponentWrapper = styled.div<
  React.AllHTMLAttributes<HTMLDivElement>
>`
  display: flex;
  gap: 10px;
  align-self: stretch;
  height: 100%;
  overflow: auto;
  background: white;
`;
