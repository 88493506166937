import React from "react";
import { truncate } from "lodash";
import styled from "styled-components";
import { Product } from "../models";

interface CompactHeaderProps {
  thumbnails: Product[];
}

const CompactHeaderProps: React.FC<CompactHeaderProps> = ({ thumbnails }) => {
  return (
    <CompactHeaderWrapper>
      <VerticalAlignedWrapper>
        <SelectedProductsWrapper>
          {thumbnails.map((thumbnail, index) => (
            <SelectedProductWrapper key={index}>
              {!thumbnail.thumbnail ? (
                <MiniLoadingImagePlaceholder />
              ) : (
                <ProductThumbnailWrapper>
                  <ProductThumbnail src={thumbnail.thumbnail} />
                </ProductThumbnailWrapper>
              )}
              {!thumbnail.name ? (
                <MiniLoadingNamePlaceholder />
              ) : (
                <ProductName>
                  {truncate(thumbnail.name, {
                    length:
                      thumbnails.length === 2
                        ? 100
                        : thumbnails.length === 3
                        ? 50
                        : 35,
                  })}
                </ProductName>
              )}
            </SelectedProductWrapper>
          ))}
        </SelectedProductsWrapper>
      </VerticalAlignedWrapper>
    </CompactHeaderWrapper>
  );
};

export default CompactHeaderProps;

const CompactHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--gray20, #e5e0e9);
  box-shadow: 0px 2px 8px 8px rgba(67, 54, 76, 0.24);
  position: fixed;
  z-index: 9999;
  background: var(--gray10, #f7f6f9);
  left: 0;
  top: 0;
  width: 100%;
`;

const VerticalAlignedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  padding: 20px 24px;
`;

const SelectedProductsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: var(--column-gap, 102px);
  align-self: stretch;
  width: 100%;
`;

const SelectedProductWrapper = styled.div<
  React.AllHTMLAttributes<HTMLDivElement>
>`
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
`;

const ProductThumbnailWrapper = styled.div`
  display: flex;
  padding: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.037px;
  border-radius: 4.291px;
  border: 1.56px solid var(--gray20, #e5e0e9);
  background: var(--white, #fff);
`;

const ProductThumbnail = styled.img<React.AllHTMLAttributes<HTMLImageElement>>`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

const ProductName = styled.div`
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  white-space: break-spaces;
  word-wrap: break-word;
`;

const MiniLoadingImagePlaceholder = styled.div`
  width: 49px;
  height: 49px;
  border-radius: 4px;
  background: linear-gradient(
    113deg,
    #e7e7e7 -14.67%,
    #f2f2f2 54.92%,
    #e8e8e8 126.88%
  );
`;

const MiniLoadingNamePlaceholder = styled.div`
  height: 39px;
  align-self: center;
  border-radius: 4px;
  background: linear-gradient(
    113deg,
    #e7e7e7 -14.67%,
    #f2f2f2 54.92%,
    #e8e8e8 126.88%
  );
  width: 190px;
`;
