import React from "react";
import styled from "styled-components";

interface TagGroupProps {
  tags: string[];
}

const TagGroup: React.FC<TagGroupProps> = ({ tags }) => (
  <TagSection>
    {tags.map((tag, index) => (
      <Tag key={index}>{tag}</Tag>
    ))}
  </TagSection>
);

export default TagGroup;

const TagSection = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const Tag = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 200px;
  color: #0d0319;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 200px;
`;
