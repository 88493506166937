import React from "react";

const SendChatIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      d="M5.06025 11.3125H13.4999V12.6875H5.06022L3.75192 19.229C3.48672 20.555 4.8626 21.6059 6.07208 21.0012L21.1677 13.4534C22.3654 12.8545 22.3654 11.1453 21.1677 10.5465L6.07208 2.9987C4.8626 2.39396 3.48672 3.44485 3.75191 4.77083L5.06025 11.3125Z"
      data-spm-anchor-id="a2700.accio_thread.0.i3.64c67b7adpA6gf"
    ></path>
  </svg>
);

export default SendChatIcon;
