import React from "react";
import styled, { keyframes } from "styled-components";
import Markdown from "react-markdown";
import { GetLikedOverviewReponseV2 } from "models/services/api";

interface Props {
  overviewResponse: GetLikedOverviewReponseV2 | undefined;
}

export const OverviewSection: React.FC<Props> = ({ overviewResponse }) => {
  return (
    <Wrapper>
      {overviewResponse ? (
        <Markdown>{overviewResponse.content}</Markdown>
      ) : (
        <LoadingWrapper>
          {[...Array(3)].map((_, index) => (
            <LoadingPlaceholderWrapper key={index} />
          ))}
        </LoadingWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--column-gap, 36px);
  align-self: stretch;
  flex-direction: column;
`;

const fadeInOut = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
`;

const LoadingPlaceholderWrapper = styled.div<
  React.AllHTMLAttributes<HTMLDivElement>
>`
  display: flex;
  width: 70%;
  flex-direction: row;
  align-items: flex-start;
  gap: 22px;
  animation: ${fadeInOut} 2s infinite;
  border-radius: 4px;
  background: linear-gradient(
    113deg,
    rgba(202, 196, 213, 0.6) -14.67%,
    rgba(202, 196, 213, 0.16) 54.92%,
    rgba(202, 196, 213, 0.6) 126.88%
  );
  height: 45px;

  &:first-of-type {
    width: 30%;
  }
`;
