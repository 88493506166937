import React, { useCallback } from "react";
import styled from "styled-components";

import { Card } from "./Card";
import { Product } from "../models";
import EmptyListPlaceHolder from "components/icon/EmptyListPlaceHolder";

interface ProductListProps {
  products: Product[];
  likedProducts: Product[];
  keywords: string[];
  scenario: string;
  favoritesCount: number;
  onCardClick: (product: Product) => void;
  onLikeCardClick: (
    productId: string,
    productLink: string,
    callback: () => void
  ) => void;
  onRemoveCardClick: (
    productId: string,
    productLink: string,
    callback: () => void
  ) => void;
}

export const ProductList: React.FC<ProductListProps> = ({
  products,
  likedProducts,
  keywords,
  scenario,
  favoritesCount,
  onCardClick,
  onLikeCardClick,
  onRemoveCardClick,
}) => {
  const onCtaClick = useCallback(
    (productId: string, productLink: string, callback: () => void) => {
      if (likedProducts.some((product) => product.id === productId)) {
        onRemoveCardClick(productId, productLink, callback);
      } else {
        onLikeCardClick(productId, productLink, callback);
      }
    },
    [likedProducts, onLikeCardClick]
  );

  return (
    <Wrapper>
      <>
        {keywords.length > 0 && (
          <Header>
            <KeywordsWrapper>
              <KeywordsTitle>
                {products.length <= 1 ? "Item" : "Items"} found:
              </KeywordsTitle>
              <Keywords>{products.length}</Keywords>
            </KeywordsWrapper>

            <KeywordsWrapper>
              <KeywordsTitle>Keywords: </KeywordsTitle>
              <Keywords>{keywords.slice(0, 6).join(", ")}</Keywords>
            </KeywordsWrapper>
          </Header>
        )}
        {products.length === 0 ? (
          <EmptyList>
            <EmptyListPlaceHolder />
            <EmptyListText>No products were found.</EmptyListText>
          </EmptyList>
        ) : (
          <Body>
            {scenario !== "_" && <ScenarioTitle>{scenario}</ScenarioTitle>}
            <ProductListWrapper>
              <Card
                product={products[0]}
                shouldShowLike={true}
                isLiked={likedProducts.some((p) => p.id === products[0].id)}
                isRecommended={true}
                onClick={() => onCardClick(products[0])}
                onLikeClick={onCtaClick}
                canLikeMore={favoritesCount < 5}
              ></Card>
              <ProductCarousel>
                {products.slice(1).map((product, index) => (
                  <Card
                    canLikeMore={favoritesCount < 5}
                    product={product}
                    shouldShowLike={true}
                    isLiked={likedProducts.some((p) => p.id === product.id)}
                    isRecommended={false}
                    key={index}
                    onClick={() => onCardClick(product)}
                    onLikeClick={onCtaClick}
                  ></Card>
                ))}
              </ProductCarousel>
            </ProductListWrapper>
          </Body>
        )}
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  margin-top: 18px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  padding-top: 20px;
  flex-direction: column;
`;

const KeywordsWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const KeywordsTitle = styled.div`
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

const Keywords = styled.div`
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const Body = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
`;

const ScenarioTitle = styled.div`
  color: var(--purple-gray90, #43364c);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  align-self: stretch;
`;

const EmptyListText = styled.div`
  color: var(--black, #0d0319);
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
`;

const ProductCarousel = styled.div`
  display: grid;
  grid-template-row: repeat(2, 1fr);
  gap: 16px;
`;

const ProductListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  padding: 12px 0;
  gap: 16px;
`;
