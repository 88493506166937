import React from "react";

const EmptyListPlaceHolder: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="113"
    height="94"
    viewBox="0 0 113 94"
    fill="none"
  >
    <g clipPath="url(#clip0_2506_23635)">
      <path
        opacity="0.1"
        d="M112.482 63.9688C109.396 48.4054 91.3138 35.9666 87.7871 19.7484C81.3773 -0.885571 60.2503 -4.14784 46.5215 4.81348C24.7683 19.0128 47.9745 22.5365 5.32344 56.1312C-5.20807 64.4264 -1.85225 101.044 31.2793 92.64C49.1092 88.1176 54.5294 87.483 62.5494 86.7383C82.7641 84.8616 117.663 90.0939 112.482 63.9688Z"
        fill="#9B89A8"
      />
      <path
        d="M74.9025 77.2778H29.9496C27.8973 77.2778 25.9112 76.5523 24.3421 75.2296C22.773 73.9069 21.722 72.0721 21.3748 70.0494L15.1973 34.0645H89.6546L83.4771 70.0494C83.13 72.0721 82.079 73.9069 80.51 75.2296C78.9409 76.5523 76.9548 77.2778 74.9025 77.2778Z"
        fill="#9B89A8"
      />
      <path
        d="M89.8161 30.5928H15.0366C13.2641 30.5928 11.8271 32.0297 11.8271 33.8022C11.8271 35.5747 13.2641 37.0116 15.0366 37.0116H89.8161C91.5886 37.0116 93.0255 35.5747 93.0255 33.8022C93.0255 32.0297 91.5886 30.5928 89.8161 30.5928Z"
        fill="white"
      />
      <path
        d="M89.8168 37.2654H15.0365C14.1183 37.2654 13.2376 36.9007 12.5883 36.2514C11.939 35.6021 11.5742 34.7214 11.5742 33.8031C11.5742 32.8849 11.939 32.0042 12.5883 31.3549C13.2376 30.7056 14.1183 30.3408 15.0365 30.3408H89.8168C90.7351 30.3408 91.6158 30.7056 92.2651 31.3549C92.9144 32.0042 93.2792 32.8849 93.2792 33.8031C93.2792 34.7214 92.9144 35.6021 92.2651 36.2514C91.6158 36.9007 90.7351 37.2654 89.8168 37.2654ZM15.0373 30.847C14.2533 30.847 13.5014 31.1585 12.947 31.7128C12.3927 32.2672 12.0812 33.0191 12.0812 33.8031C12.0812 34.5871 12.3927 35.339 12.947 35.8934C13.5014 36.4478 14.2533 36.7593 15.0373 36.7593H89.8168C90.6009 36.7593 91.3528 36.4478 91.9071 35.8934C92.4615 35.339 92.773 34.5871 92.773 33.8031C92.773 33.0191 92.4615 32.2672 91.9071 31.7128C91.3528 31.1585 90.6009 30.847 89.8168 30.847H15.0373Z"
        fill="#262626"
      />
      <path
        d="M62.3306 31.8311C61.4946 32.8822 61.6689 34.412 62.7199 35.248C63.771 36.084 65.3008 35.9097 66.1368 34.8586L80.4484 16.8657C81.2845 15.8147 81.1101 14.2849 80.0591 13.4489C79.008 12.6129 77.4783 12.7872 76.6422 13.8383L62.3306 31.8311Z"
        fill="white"
      />
      <path
        d="M64.2271 36.031C63.6215 36.0313 63.0339 35.8253 62.5611 35.4468C62.0045 35.0031 61.6467 34.3568 61.5661 33.6496C61.4855 32.9424 61.6888 32.2321 62.1312 31.6745L76.4427 13.6814C76.6622 13.4055 76.9339 13.1755 77.2423 13.0046C77.5507 12.8336 77.8897 12.7251 78.24 12.6852C78.5904 12.6453 78.9451 12.6748 79.284 12.772C79.623 12.8692 79.9394 13.0322 80.2154 13.2517C80.4913 13.4712 80.7213 13.7429 80.8922 14.0512C81.0631 14.3596 81.1717 14.6987 81.2116 15.049C81.2515 15.3993 81.222 15.754 81.1248 16.093C81.0276 16.4319 80.8646 16.7484 80.6451 17.0243L66.3338 35.0172C66.115 35.2938 65.8434 35.5243 65.5349 35.6953C65.2264 35.8663 64.887 35.9744 64.5364 36.0134C64.4337 36.0251 64.3304 36.0309 64.2271 36.031ZM62.8761 35.0508C63.1001 35.2289 63.3569 35.3612 63.6319 35.4401C63.9069 35.5189 64.1948 35.5429 64.4791 35.5105C64.7634 35.4781 65.0385 35.39 65.2888 35.2513C65.539 35.1125 65.7595 34.9259 65.9376 34.7019L80.2489 16.7088C80.427 16.4849 80.5593 16.2281 80.6382 15.9531C80.7171 15.6781 80.7411 15.3902 80.7087 15.1059C80.6764 14.8216 80.5884 14.5465 80.4497 14.2962C80.311 14.046 80.1244 13.8255 79.9005 13.6473C79.6766 13.4692 79.4198 13.3369 79.1448 13.258C78.8698 13.179 78.5819 13.1551 78.2976 13.1874C78.0133 13.2198 77.7382 13.3078 77.4879 13.4465C77.2376 13.5852 77.0171 13.7718 76.839 13.9957L62.5275 31.9886C62.1684 32.441 62.0035 33.0174 62.0688 33.5913C62.1342 34.1652 62.4245 34.6897 62.8761 35.0498V35.0508Z"
        fill="#262626"
      />
      <path
        d="M64.0697 34.4666C64.5758 34.4666 64.9861 34.0563 64.9861 33.5502C64.9861 33.0441 64.5758 32.6338 64.0697 32.6338C63.5636 32.6338 63.1533 33.0441 63.1533 33.5502C63.1533 34.0563 63.5636 34.4666 64.0697 34.4666Z"
        fill="white"
      />
      <path
        d="M64.0722 34.7204C63.8992 34.7205 63.7283 34.6823 63.5719 34.6085C63.4154 34.5347 63.2772 34.4271 63.1673 34.2935C63.0573 34.1599 62.9783 34.0037 62.936 33.8359C62.8937 33.6681 62.889 33.4931 62.9224 33.3233C62.9655 33.1062 63.0694 32.9058 63.222 32.7453C63.3745 32.5849 63.5695 32.4711 63.7842 32.4171C63.9989 32.3631 64.2245 32.3712 64.4348 32.4404C64.6451 32.5096 64.8314 32.6371 64.9721 32.8081C65.1128 32.979 65.2021 33.1864 65.2295 33.406C65.257 33.6257 65.2216 33.8487 65.1273 34.049C65.033 34.2493 64.8838 34.4187 64.6971 34.5376C64.5103 34.6565 64.2936 34.7199 64.0722 34.7204ZM63.4191 33.4217C63.3891 33.5723 63.4124 33.7286 63.4848 33.864C63.5572 33.9993 63.6743 34.1054 63.8161 34.1641C63.958 34.2228 64.1158 34.2305 64.2627 34.1859C64.4096 34.1413 64.5364 34.0472 64.6217 33.9195C64.7069 33.7918 64.7452 33.6385 64.7301 33.4857C64.715 33.333 64.6474 33.1902 64.5388 33.0817C64.4302 32.9732 64.2873 32.9057 64.1345 32.8907C63.9818 32.8757 63.8285 32.9142 63.7009 32.9996C63.5548 33.0974 63.4534 33.2493 63.4191 33.4217Z"
        fill="#262626"
      />
      <path
        d="M28.2145 13.8405C27.3785 12.7894 25.8487 12.6151 24.7976 13.4511C23.7466 14.2871 23.5723 15.8169 24.4083 16.868L38.7199 34.8608C39.556 35.9119 41.0857 36.0862 42.1368 35.2502C43.1878 34.4142 43.3622 32.8844 42.5261 31.8333L28.2145 13.8405Z"
        fill="white"
      />
      <path
        d="M40.6263 36.0315C40.222 36.0323 39.8227 35.9417 39.4583 35.7664C39.094 35.5911 38.774 35.3356 38.5224 35.0191L24.2107 17.0262C23.7674 16.469 23.5636 15.7584 23.6442 15.0509C23.7248 14.3434 24.0832 13.6969 24.6404 13.2536C25.1977 12.8104 25.9082 12.6066 26.6157 12.6872C27.3232 12.7678 27.9697 13.1261 28.413 13.6834L42.7247 31.6765C43.0392 32.0715 43.2358 32.5471 43.2921 33.0489C43.3483 33.5506 43.2619 34.058 43.0428 34.5128C42.8236 34.9676 42.4806 35.3514 42.0532 35.6201C41.6257 35.8887 41.1312 36.0313 40.6263 36.0315ZM26.3097 13.177C25.9001 13.1773 25.4989 13.2931 25.1521 13.5111C24.8053 13.7292 24.527 14.0406 24.3492 14.4096C24.1714 14.7786 24.1013 15.1903 24.1469 15.5974C24.1924 16.0045 24.3519 16.3904 24.6069 16.711L38.9184 34.7039C39.2785 35.1555 39.803 35.4459 40.3769 35.5113C40.9508 35.5767 41.5272 35.4118 41.9796 35.0528C42.2036 34.8747 42.3902 34.6542 42.5289 34.404C42.6677 34.1538 42.7557 33.8787 42.7881 33.5944C42.8205 33.3101 42.7966 33.0223 42.7178 32.7473C42.6389 32.4722 42.5066 32.2154 42.3285 31.9915L28.017 13.9986C27.8128 13.7419 27.5531 13.5346 27.2575 13.3923C26.9618 13.25 26.6378 13.1764 26.3097 13.177Z"
        fill="#262626"
      />
      <path
        d="M40.7855 34.4695C41.2917 34.4695 41.7019 34.0592 41.7019 33.5531C41.7019 33.047 41.2917 32.6367 40.7855 32.6367C40.2794 32.6367 39.8691 33.047 39.8691 33.5531C39.8691 34.0592 40.2794 34.4695 40.7855 34.4695Z"
        fill="white"
      />
      <path
        d="M40.7829 34.7222C40.5613 34.7218 40.3445 34.6585 40.1575 34.5397C39.9706 34.4209 39.8212 34.2514 39.7268 34.051C39.6324 33.8506 39.5968 33.6276 39.6242 33.4077C39.6516 33.1879 39.7409 32.9804 39.8817 32.8093C40.0224 32.6383 40.2088 32.5107 40.4192 32.4414C40.6297 32.3722 40.8554 32.3641 41.0702 32.4181C41.2851 32.4722 41.4801 32.5861 41.6328 32.7467C41.7854 32.9072 41.8892 33.1078 41.9323 33.3251C41.9657 33.4949 41.9611 33.6699 41.9188 33.8376C41.8765 34.0054 41.7976 34.1616 41.6877 34.2952C41.5777 34.4288 41.4396 34.5364 41.2832 34.6102C41.1267 34.684 40.9559 34.7223 40.7829 34.7222ZM40.7867 32.889C40.6499 32.8887 40.5164 32.9306 40.4044 33.0092C40.2924 33.0877 40.2074 33.1989 40.1611 33.3276C40.1147 33.4563 40.1093 33.5962 40.1456 33.728C40.1818 33.8599 40.2579 33.9774 40.3635 34.0643C40.4691 34.1513 40.599 34.2035 40.7354 34.2137C40.8718 34.224 41.008 34.1919 41.1254 34.1217C41.2428 34.0515 41.3357 33.9468 41.3913 33.8218C41.4468 33.6968 41.4624 33.5577 41.4358 33.4235C41.406 33.2732 41.325 33.1378 41.2067 33.0404C41.0884 32.943 40.94 32.8895 40.7867 32.889Z"
        fill="#262626"
      />
      <path
        d="M54.321 48.2983C54.321 47.2517 53.4726 46.4033 52.4261 46.4033C51.3796 46.4033 50.5312 47.2517 50.5312 48.2983V66.2134C50.5312 67.26 51.3796 68.1084 52.4261 68.1084C53.4726 68.1084 54.321 67.26 54.321 66.2134V48.2983Z"
        fill="white"
      />
      <path
        d="M36.2068 48.1144C36.046 47.0803 35.0773 46.3723 34.0433 46.5331C33.0092 46.6939 32.3012 47.6625 32.462 48.6967L35.2145 66.3991C35.3753 67.4332 36.344 68.1412 37.378 67.9804C38.4121 67.8197 39.1201 66.851 38.9593 65.8169L36.2068 48.1144Z"
        fill="white"
      />
      <path
        d="M66.2847 65.8168C66.1239 66.8509 66.8318 67.8196 67.8659 67.9804C68.9 68.1411 69.8686 67.4332 70.0294 66.399L72.7819 48.6966C72.9427 47.6625 72.2348 46.6938 71.2007 46.533C70.1666 46.3722 69.198 47.0802 69.0372 48.1143L66.2847 65.8168Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2506_23635">
        <rect
          width="113"
          height="93.77"
          fill="white"
          transform="translate(0 0.115234)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default EmptyListPlaceHolder;
