export type Job = {
    name: string;
    intervalSeconds: number;
    executor: () => Promise<void>;
    version?: number;
};

type ManagedJob = Job & {
    isRunning: boolean;
    intervalJobId: NodeJS.Timeout | number;
    jobStat: JobStat;
};

type JobStat = {
    id: string;
    registeredAt?: string; // isotimestamp
    lastRunAt?: string; // isotimestamp
    version?: number; // persisted job version
};

const LOCAL_JOB_PREFIX = 'job_manager:';

class JobManager {
    private _jobs: Map<string, ManagedJob> = new Map();

    public addJob(job: Job, overrideExistingJob=false, executeImmediatelyIfNoLastRun=false) {
        const existingJob = this._jobs.get(job.name);
        if (existingJob && overrideExistingJob) {
            clearInterval(existingJob.intervalJobId);
        }
        if (!existingJob || overrideExistingJob) {
            // this._jobs.set(job.name, this._enrichJob(job, executeImmediatelyIfNoLastRun));
        }
        console.debug(`job ${job.name} registered`);
    }

    public refreshJob(jobName: string) {
        const job = this._jobs.get(jobName);
        if (job) {
            clearInterval(job.intervalJobId);
            job.jobStat.lastRunAt = new Date().toISOString();
            this._persistJobStat(job.jobStat);
            job.intervalJobId = setTimeout(() => this._executeJobAndScheduleNextJob(jobName, true), 0);
        }
    }

    public removeJob(jobName: string) {
        const job = this._jobs.get(jobName);
        clearInterval(job?.intervalJobId);
        this._jobs.delete(jobName);
    }

    private _getJobStat(jobName: string): JobStat | undefined {
        const localJobStat = localStorage.getItem(`${LOCAL_JOB_PREFIX}${jobName}`);
        return localJobStat? JSON.parse(localJobStat): undefined;
    }

    private _persistJobStat(job: JobStat) {
        localStorage.setItem(`${LOCAL_JOB_PREFIX}${job.id}`, JSON.stringify(job));
    }

    private _enrichJob(job: Job, executeImmediatelyIfNoLastRun=false): ManagedJob {
        let jobStat = this._getJobStat(job.name);
        if (!jobStat) {
            jobStat = {
                id: job.name,
                registeredAt: new Date().toISOString(),
            };
            this._persistJobStat(jobStat);
        }
        if (jobStat.version !== job.version) {
            console.warn(`job ${job.name} version changed from ${jobStat.version} to ${job.version}, overriding`);
            jobStat.version = job.version;
            jobStat.lastRunAt = undefined;
            this._persistJobStat(jobStat);
        }
        const managedJob = {
            ...job,
            isRunning: false,
            intervalJobId: setTimeout(() => this._executeJobAndScheduleNextJob(job.name, true, executeImmediatelyIfNoLastRun), 0),
            jobStat: jobStat,
        };
        return managedJob;
    }

    private async _executeJobAndScheduleNextJob(jobName: string, forceRunEvenLate=false, executeImmediatelyIfNoLastRun=false) {
        const startTime = new Date().valueOf();
        const job = this._jobs.get(jobName);
        if (!job) {
            console.error(`failed to run job ${jobName} due to job not found`);
            return;
        }
        if (job.jobStat.lastRunAt) {
            const lastRunTime = new Date(job.jobStat.lastRunAt).valueOf();
            const nextRunTime = lastRunTime + job.intervalSeconds * 1000;
            // if next runtime is earlier than current(start) time, schedule next run time or force run
            if (nextRunTime < startTime) {
                console.warn(`job ${job.name} is late to run, scheduled next run time ${nextRunTime}`);
                job.intervalJobId = setTimeout(() => this._executeJobAndScheduleNextJob(jobName), nextRunTime);
                if (!forceRunEvenLate) {
                    return;
                }
                console.warn(`job ${job.name} is late to run, but forceRunEventLate = true, continue running the job`);
            }
            // if next runtime is at least 30 seconds later than current(start) time, schedule it to later
            const msToStart = nextRunTime - startTime;
            if (msToStart > 1000 * 30) {
                setTimeout(() => this._executeJobAndScheduleNextJob(jobName), msToStart);
                return;
            }
        } else if (!executeImmediatelyIfNoLastRun) {
            const lastRunAt = new Date(startTime).toISOString();
            console.log(`job ${jobName} has no last run, marking its last run at to be ${lastRunAt}`)
            job.jobStat.lastRunAt = lastRunAt;
            this._persistJobStat(job.jobStat);
            setTimeout(() => this._executeJobAndScheduleNextJob(jobName), 0);
            return;
        }

        try {
            job.isRunning = true;
            console.log(`running job ${job.name}`);
            await job.executor();
            console.log(`job ${job.name} completed`);
        } catch (e) {
            console.error(`failed to run job ${job.name}`, JSON.stringify(e));
        } finally {
            job.isRunning = false;
            // update and persist job stat
            job.jobStat.lastRunAt = new Date(startTime).toISOString();
            this._persistJobStat(job.jobStat);
            const nextRunTime = new Date(startTime + job.intervalSeconds * 1000);
            console.log(`next run time for ${job.name} is ${nextRunTime}`);
            // schedule next run time based on start time
            job.intervalJobId = setTimeout(() => this._executeJobAndScheduleNextJob(jobName), Math.max(0, nextRunTime.valueOf() - new Date().valueOf()));
        }
    }
}

export const jobManager = new JobManager();
