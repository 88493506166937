import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Markdown from "react-markdown";

import PromptLogo from "components/icon/PromptLogo";
import { ChatProductInfo } from "models/services/api";
import { ProductList } from "./ProductList";
import { Product, ProductGroup } from "../models";

export type LayoutScheme = "question" | "response" | "unknown";

export interface SectionContent {
  content: string | ChatProductInfo[];
  suggestedQuestions: string[] | undefined;
  layoutScheme: LayoutScheme;
  relatedVideos: string[] | undefined;
  productGroup: ProductGroup;
  productGroupToken: string;
  keywords: string[];
  intent: string | undefined;
  placeholder: string;
  token: string | undefined;
}

interface PromptSectionProps {
  sectionContent: SectionContent;
  sessionId: string;
  cls: string;
  likedProducts: Product[];
  favoritesCount: number;
  onSuggestedQuestionClick: (question: string) => void;
  onCardClick: (product: Product) => void;
  onLikeCardClick: (
    productId: string,
    productLink: string,
    callback: () => void
  ) => void;
  onRemoveCardClick: (
    productId: string,
    productLink: string,
    callback: () => void
  ) => void;
  onGeneralTextPrinted: () => void;
}

const PromptSection: React.FC<PromptSectionProps> = ({
  sectionContent,
  cls,
  likedProducts,
  favoritesCount,
  onSuggestedQuestionClick,
  onCardClick,
  onLikeCardClick,
  onRemoveCardClick,
  onGeneralTextPrinted,
}) => {
  const [displayText, setDisplayText] = useState<string>("");
  const displayTextRef = useRef(displayText);
  displayTextRef.current = displayText;

  useEffect(() => {
    if (
      sectionContent.layoutScheme === "response" &&
      typeof sectionContent.content === "string" &&
      sectionContent.intent === "general"
    ) {
      let index = displayTextRef.current.length;

      const intervalId = setInterval(() => {
        if (index < sectionContent.content.length) {
          const nextText = sectionContent.content.slice(index, index + 10);
          setDisplayText((t) => {
            return t + nextText;
          });
          index += nextText.length;

          onGeneralTextPrinted();
        } else {
          clearInterval(intervalId);
        }
      }, 25);
      return () => clearInterval(intervalId);
    }
  }, [sectionContent]);

  const renderContent = useCallback(() => {
    if (typeof sectionContent.content === "string") {
      if (sectionContent.content.length === 0) {
        return <>{sectionContent.placeholder}</>;
      } else if (sectionContent.intent === "general") {
        return <Markdown>{displayText}</Markdown>;
      } else {
        return <Markdown>{sectionContent.content as string}</Markdown>;
      }
    } else {
      return sectionContent.content;
    }
  }, [sectionContent, displayText]);

  return (
    <Wrapper>
      {sectionContent.layoutScheme === "question" && (
        <QuestionWrapper>{sectionContent.content}</QuestionWrapper>
      )}
      {sectionContent.layoutScheme === "response" && (
        <ResultWrapper className={cls}>
          <PromptLogoWrapper>
            <PromptLogo />
          </PromptLogoWrapper>
          <ResultSectionWrapper>
            <ResultContent>{renderContent()}</ResultContent>
            {sectionContent.productGroup &&
              Object.keys(sectionContent.productGroup).length > 0 &&
              Object.keys(sectionContent.productGroup).map(
                (scenario, index) => (
                  <ProductList
                    key={index}
                    onCardClick={onCardClick}
                    onLikeCardClick={onLikeCardClick}
                    onRemoveCardClick={onRemoveCardClick}
                    products={sectionContent.productGroup[scenario]}
                    likedProducts={likedProducts}
                    keywords={sectionContent.keywords}
                    scenario={scenario}
                    favoritesCount={favoritesCount}
                  ></ProductList>
                )
              )}

            {sectionContent.relatedVideos &&
              sectionContent.relatedVideos.length > 0 && (
                <RelatedVideosWrapper>
                  <RelatedVideosTitle>Related videos</RelatedVideosTitle>
                  <RelatedVideos>
                    {sectionContent.relatedVideos.map((video, index) => (
                      <RelatedVideo
                        key={index}
                        dangerouslySetInnerHTML={{ __html: video }}
                      ></RelatedVideo>
                    ))}
                  </RelatedVideos>
                </RelatedVideosWrapper>
              )}
            {sectionContent.suggestedQuestions &&
              sectionContent.suggestedQuestions.length > 0 && (
                <SuggestedQuestionsWrapper>
                  <SuggestedQuestionsTitle>
                    Suggested questions
                  </SuggestedQuestionsTitle>
                  <SuggestedQuestions>
                    {sectionContent.suggestedQuestions.map(
                      (question, index) => (
                        <SuggestedQuestion
                          key={index}
                          onClick={() => onSuggestedQuestionClick(question)}
                        >
                          {question}
                        </SuggestedQuestion>
                      )
                    )}
                  </SuggestedQuestions>
                </SuggestedQuestionsWrapper>
              )}
          </ResultSectionWrapper>
        </ResultWrapper>
      )}
    </Wrapper>
  );
};

export default PromptSection;

const Wrapper = styled.div`
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;
`;

const QuestionWrapper = styled.div`
  a {
    color: #9b59b6;
  }
  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 200px;
  background: var(--purple-gray90, #43364c);
  color: var(--white, #fff);
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  align-self: end;
`;

const ResultSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  padding-right: 34px;
  width: calc(100% - 120px);
  gap: 24px;
`;

const ResultWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 100%;
`;

const PromptLogoWrapper = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
`;

const ResultContent = styled.div`
  flex: 1 0 0;
  align-self: stretch;
  color: var(--gray70, #43364c);
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;

  p:first-of-type,
  h1:first-of-type,
  h2:first-of-type,
  h3:first-of-type,
  h4:first-of-type,
  h5:first-of-type,
  h6:first-of-type {
    margin-top: 0;
  }
`;

const SuggestedQuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

const SuggestedQuestionsTitle = styled.div`
  color: var(--purple-gray90, #74697b);
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
`;

const SuggestedQuestions = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const SuggestedQuestion = styled.li<React.AllHTMLAttributes<HTMLUListElement>>`
  color: var(--purple-gray90, #74697b);
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const RelatedVideosTitle = styled.div`
  color: var(--purple-gray90, #74697b);
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
`;

const RelatedVideos = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-top: 24px;
`;

const RelatedVideosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const RelatedVideo = styled.div<React.AllHTMLAttributes<HTMLUListElement>>`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  iframe {
    width: 320px;
    height: 180px;
  }
`;
