import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import { isEmpty } from "lodash";

import { Specification } from "./models";

interface SpecificationsSectionProps {
  specifications?: Specification;
}

export const SpecificationsSection: React.FC<SpecificationsSectionProps> = ({
  specifications,
}) => {
  const renderContent = () => {
    if (!isEmpty(specifications)) {
      if (specifications.textcontent) {
        return <Markdown>{specifications.textcontent}</Markdown>;
      } else if (
        !isEmpty(specifications.original_content) &&
        !isEmpty(specifications.original_content.list)
      ) {
        return specifications.original_content.list.map((content) => {
          return (
            <>
              <Category>{content.category}</Category>
              <Specifications>
                {content.attributes.map((attr, index) => (
                  <SpecificationRow key={index}>
                    {attr.name}: {attr.value}
                  </SpecificationRow>
                ))}
              </Specifications>
            </>
          );
        });
      } else {
        return null;
      }
    }
  };
  return (
    <Wrapper>
      <Content>{renderContent()}</Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const Content = styled.div`
  align-self: stretch;
  color: var(--purple-gray90, #43364c);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 0;
  }

  ul {
    margin-top: 0;
  }
`;

const Category = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: "Noto Sans";
`;

const Specifications = styled.ul``;

const SpecificationRow = styled.li<React.AllHTMLAttributes<HTMLDivElement>>``;
