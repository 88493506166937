import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import { parseJsonToObject } from "../../../common/json";
import {
  sendChatV2,
  getSuggestedQuestions,
  getPromptResponse,
  cancelPrompt,
  getRelatedVideos,
  removeLikedProducts,
  addLikedProduct,
  getLikedProducts,
  getProductGroup,
  getGeneralResponse,
} from "../../../services/product";
import {
  GetProductGroupResponseV2,
  GetPromptResponse,
} from "models/services/api";
import { getChatHistory } from "../../../services/chat";
import PromptSection, { SectionContent } from "./PromptSection";
import logo from "assets/images/logo.png";
import { AppContext } from "context/AppContext";
import PromptLogo from "components/icon/PromptLogo";
import { fadeInOut } from "../common";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";
import { Product, ProductGroup } from "../models";
import ChatIcon from "components/icon/ChatIcon";
import LikeIcon from "components/icon/LikeIcon";
import SendChatIcon from "components/icon/SendChatIcon";
import { Favorites } from "./Favorites";

enum Tab {
  Search = "Search",
  Favorites = "Favorites",
}

const productGroupConverter = (response: GetProductGroupResponseV2) => {
  if (response.products) {
    return Object.entries(response.products).reduce(
      (acc, [scenario, products]) => {
        acc[scenario] = products.map((p) => ({
          name: p.name,
          tags: [],
          rating: p.rating,
          price: p.best_offer_price.split("+")[0],
          id: p.product_id,
          link: p.url,
          thumbnail: p.image_url,
          buyReason: p.buy_reason,
        }));
        return acc;
      },
      {} as ProductGroup
    );
  } else {
    return {};
  }
};

interface PromptProps {
  sessionId: string;
  userId: string;
  onProductClick: (product: Product) => void;
}

const PromptLoadingPlaceholder = "AI is analyzing...";

const Prompt: React.FC<PromptProps> = ({
  sessionId,
  userId,
  onProductClick,
}) => {
  const [inputText, setInputText] = useState<string>("");
  const [isLoadingChatHistory, setLoadingChatHistory] =
    useState<boolean>(false);
  const [isProcessingPrompt, setIsProcessingPrompt] = useState<boolean>(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);
  const [isLoadingIntent, setIsLoadingIntent] = useState<boolean>(false);
  useState<boolean>(false);
  const [isStopZButtonVisible, setIsStopZButtonVisible] =
    useState<boolean>(false);
  const [promptToken, setPromptToken] = useState<string>("");
  const [promptSections, setPromptSections] = useState<SectionContent[]>([]);
  const {
    state: { homepageQueryText },
    dispatch,
  } = useContext(AppContext);
  const [suggestedQuestionsCreatedAt, setSuggestedQuestionsCreatedAt] =
    useState<Date | undefined>(undefined);
  const [relatedVideosCreatedAt, setRelatedVideosCreatedAt] = useState<
    Date | undefined
  >(undefined);
  const history = useHistory();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(
    undefined
  );
  const [likedProducts, setLikedProducts] = useState<Product[]>([]);
  const [activeTab, setActiveTab] = useState<Tab>(Tab.Search);
  const promptSectionsRef = useRef(promptSections);
  promptSectionsRef.current = promptSections;
  const activeTabRef = useRef(activeTab);
  activeTabRef.current = activeTab;
  const [proudctsToken, setProductsToken] = useState<string | undefined>(
    undefined
  );
  const [favoritesCount, setFavoritesCount] = useState<number>(0);
  const [needScroll, setNeedscroll] = useState<boolean>(false);
  const [shoudFetchRelatedVideos, setShoudFetchRelatedVideos] =
    useState<boolean>(false);
  const [shoudFetchSuggestedQuestions, setShoudFetchSuggestedQuestions] =
    useState<boolean>(false);

  const getPromptResponseWrapper = (token: string) => {
    getPromptResponse(token).then((res) => {
      if (isEmpty(res)) {
        const id = setTimeout(() => getPromptResponseWrapper(token), 1000);
        setTimeoutId(id);
      } else {
        setPromptSections((promptSections) => {
          const lastSection = promptSections[promptSections.length - 1];
          return [
            ...promptSections.slice(0, promptSections.length - 1),
            {
              ...lastSection,
              content: res.response,
            } as SectionContent,
          ];
        });

        setIsProcessingPrompt(false);
      }
    });
  };

  const getGeneralResponseWrapper = (sessionId: string, token: string) => {
    getGeneralResponse(sessionId, token).then((res) => {
      if (res.status === "IN_PROGRESS") {
        if (res.response.length > 0) {
          setIsStopZButtonVisible(false);

          setPromptSections((ps) => {
            const newPs = cloneDeep(ps);
            newPs.forEach((p) => {
              if (p.token === token) {
                p.content = res.response;
              }
            });
            return newPs;
          });
        }

        const id = setTimeout(
          () => getGeneralResponseWrapper(sessionId, token),
          1000
        );
        setTimeoutId(id);
      } else {
        setPromptSections((ps) => {
          const newPs = cloneDeep(ps);
          newPs.forEach((p) => {
            if (p.token === token) {
              p.content = res.response;
            }
          });
          return newPs;
        });
      }
    });
  };

  const fetchProductGroup = (token: string, count = 0) => {
    if (token) {
      setIsLoadingProducts(true);

      getProductGroup(sessionId, token).then((res) => {
        setPromptSections((promptSections) => {
          promptSections.forEach((promptSection) => {
            if (promptSection.productGroupToken === token) {
              promptSection.productGroup = productGroupConverter(res);
            }
          });
          return promptSections;
        });

        if (res.status === "NOT_READY") {
          setTimeout(() => fetchProductGroup(token, count + 1), 1000);
        } else {
          setIsLoadingProducts(false);
          setShoudFetchRelatedVideos(true);
          setShoudFetchSuggestedQuestions(true);
        }
      });
    }
  };

  const submitQuery = useCallback(
    (inputText: string) => {
      setIsLoadingIntent(true);
      setIsProcessingPrompt(true);
      setIsStopZButtonVisible(false);

      setPromptSections((promptSections) => [
        ...promptSections,
        {
          content: inputText,
          layoutScheme: "question",
          suggestedQuestions: undefined,
          relatedVideos: undefined,
          productGroup: {},
          keywords: [],
          productGroupToken: "",
          intent: undefined,
          placeholder: "",
          token: undefined,
        },
        {
          content: "",
          layoutScheme: "response",
          suggestedQuestions: undefined,
          relatedVideos: undefined,
          productGroup: {},
          keywords: [],
          productGroupToken: "",
          intent: undefined,
          placeholder: PromptLoadingPlaceholder,
          token: undefined,
        },
      ]);

      sendChatV2(userId, sessionId, inputText)
        .then((res) => {
          setPromptSections((promptSections) => {
            const lastSection = promptSections[promptSections.length - 1];
            const newPromptSections: SectionContent[] = [
              ...promptSections.slice(0, promptSections.length - 1),
              {
                ...lastSection,
                placeholder:
                  res.intent === "search"
                    ? `AI is searching for "${inputText}"...`
                    : "AI is generating response...",
                productGroupToken: res.products_token || "",
                intent: res.intent,
                token: res.token || "",
              } as SectionContent,
            ];

            return newPromptSections;
          });

          setPromptToken(res.token);
          setProductsToken(res.products_token);

          if (res.intent === "search") {
            fetchProductGroup(res.products_token);
            getPromptResponseWrapper(res.token);
          } else if (res.intent === "general") {
            setIsStopZButtonVisible(true);
            getGeneralResponseWrapper(sessionId, res.token);
          }

          setIsLoadingIntent(false);
        })
        .catch((err) => {
          console.error(
            "failed to sent chat text due to ",
            JSON.stringify(err)
          );
          // clear failed messages
          setPromptSections((promptSections) => promptSections.slice(0, -1));
          setInputText(inputText);
          setIsProcessingPrompt(false);
          setIsLoadingIntent(false);
          setIsStopZButtonVisible(false);
          alert("Failed to send last message, please retry");
        });
    },
    [sessionId, userId]
  );

  useEffect(() => {
    if (needScroll) {
      const dialogWrapper = document.getElementById("dialog-wrapper");
      if (dialogWrapper) {
        dialogWrapper?.scrollTo({
          top: dialogWrapper.scrollHeight,
          behavior: "smooth",
        });
      }
      setNeedscroll(false);
    }
  }, [needScroll]);

  useEffect(() => {
    if (!isLoadingProducts && !isProcessingPrompt) {
      const section = document.getElementsByClassName(
        "question-prompt-section"
      );
      const dialogWrapper = document.getElementById("dialog-wrapper");
      if (dialogWrapper && section.length > 0) {
        dialogWrapper?.scrollTo({
          top: (section[section.length - 1] as HTMLDivElement).offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [isLoadingProducts, isProcessingPrompt]);

  useEffect(() => {
    if (homepageQueryText) {
      submitQuery(homepageQueryText);
      dispatch({ type: "SET_HOMEPAGE_QUERY_TEXT", payload: undefined });
    } else {
      setLoadingChatHistory(true);

      getLikedProducts(sessionId, userId)
        .then((res) => {
          setLikedProducts(
            res.map((product) => {
              return {
                name: product.name,
                tags: [],
                //product.highlights.split(",").map((highlight) => highlight.trimStart()),
                rating: product.ratings,
                thumbnail: product.image_links[0],
                price: product.typical_prices
                  ? product.typical_prices.low_price
                  : "-",
                id: product.product_id,
                link: product.link,
              };
            })
          );

          setFavoritesCount(res.length);
        })
        .catch(() => {
          setFavoritesCount((c) => c - 1);
        });

      getChatHistory(sessionId).then((messages) => {
        if (messages.length) {
          const newPromptSections: SectionContent[] = messages.map(
            (message) => {
              if (message.role === "user") {
                return {
                  layoutScheme: "question",
                  content: message.message,
                  suggestedQuestions: undefined,
                  relatedVideos: undefined,
                  productGroup: {},
                  keywords: [],
                  estimatedProductCount: 0,
                  productGroupToken: "",
                  intent: undefined,
                  placeholder: "",
                  token: undefined,
                };
              } else if (message.role === "system") {
                const response = parseJsonToObject(
                  message.message
                ) as GetPromptResponse;

                return {
                  layoutScheme: "response",
                  content: response.response,
                  suggestedQuestions: undefined,
                  relatedVideos: undefined,
                  productGroup: {},
                  keywords: [],
                  estimatedProductCount: 0,
                  productGroupToken: response.products_token || "",
                  intent: undefined,
                  placeholder: "",
                  token: undefined,
                };
              } else {
                return {
                  layoutScheme: "unknown",
                  content: "",
                  suggestedQuestions: undefined,
                  relatedVideos: undefined,
                  productGroup: {},
                  keywords: [],
                  estimatedProductCount: 0,
                  productGroupToken: "",
                  intent: undefined,
                  placeholder: "",
                  token: undefined,
                };
              }
            }
          );

          setPromptSections(newPromptSections);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      isLoadingChatHistory &&
      promptSectionsRef &&
      promptSectionsRef.current.length > 0
    ) {
      const allPromises: Promise<void>[] = [];
      promptSectionsRef.current.forEach((p, index) => {
        if (p.productGroupToken.length > 0) {
          const promise1 = getProductGroup(sessionId, p.productGroupToken).then(
            (res) => {
              const productGroup = productGroupConverter(res);
              setPromptSections((promptSections) => {
                promptSections[index].productGroup = productGroup;
                return promptSections;
              });
            }
          );

          const promise2 = getRelatedVideos(
            sessionId,
            p.productGroupToken
          ).then((res) => {
            setPromptSections((p) => {
              p[index].relatedVideos = res.videos;
              return p;
            });
          });

          allPromises.push(promise1);
          allPromises.push(promise2);
        }
      });

      Promise.all(allPromises).then(() => {
        setLoadingChatHistory(false);
        setNeedscroll(true);
      });
    }
  }, [isLoadingChatHistory, promptSectionsRef, promptSections]);

  const fetchRelatedVideos = useCallback(
    (count = 0) => {
      if (proudctsToken) {
        getRelatedVideos(sessionId, proudctsToken!).then((res) => {
          if (
            res.created_at &&
            (!relatedVideosCreatedAt ||
              new Date(res.created_at) > relatedVideosCreatedAt)
          ) {
            setShoudFetchRelatedVideos(false);
            setPromptSections((p) => [
              ...p.slice(0, p.length - 1),
              {
                ...p[p.length - 1],
                relatedVideos: res.videos,
              },
            ]);
            setRelatedVideosCreatedAt(new Date(res.created_at));
          } else {
            if (count < 10) {
              setTimeout(() => {
                fetchRelatedVideos(count + 1);
              }, 2000);
            } else {
              setShoudFetchRelatedVideos(false);
            }
          }
        });
      }
    },
    [relatedVideosCreatedAt, sessionId, proudctsToken]
  );

  const fetchSuggestedQuestions = useCallback(
    (count = 0) => {
      getSuggestedQuestions(sessionId).then((res) => {
        if (
          res.created_at &&
          (!suggestedQuestionsCreatedAt ||
            new Date(res.created_at) > suggestedQuestionsCreatedAt)
        ) {
          setShoudFetchSuggestedQuestions(false);
          setPromptSections((p) => [
            ...p.slice(0, p.length - 1),
            {
              ...p[p.length - 1],
              suggestedQuestions: res.questions,
            },
          ]);
          setSuggestedQuestionsCreatedAt(new Date(res.created_at));
        } else {
          if (count < 10) {
            setTimeout(() => {
              fetchSuggestedQuestions(count + 1);
            }, 2000);
          } else {
            setShoudFetchSuggestedQuestions(false);
          }
        }
      });
    },
    [suggestedQuestionsCreatedAt, sessionId]
  );

  useEffect(() => {
    if (shoudFetchRelatedVideos) {
      fetchRelatedVideos();
    }
  }, [shoudFetchRelatedVideos, fetchRelatedVideos]);

  useEffect(() => {
    if (shoudFetchSuggestedQuestions) {
      fetchSuggestedQuestions();
    }
  }, [shoudFetchSuggestedQuestions, fetchSuggestedQuestions]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        sendPrompt();
      }
    },
    [inputText, isLoadingProducts, isProcessingPrompt]
  );

  const sendPrompt = useCallback(() => {
    if (sessionId && inputText && !isLoadingIntent && !isLoadingChatHistory) {
      submitQuery(inputText);
      setInputText("");
      setNeedscroll(true);
    }
  }, [inputText, isLoadingProducts, isProcessingPrompt]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleStopClick = useCallback(() => {
    clearTimeout(timeoutId);
    setIsStopZButtonVisible(false);
    setPromptSections((promptSections) => [
      ...promptSections.slice(0, promptSections.length - 2),
    ]);
    setIsProcessingPrompt(false);

    cancelPrompt(sessionId, userId, promptToken);
  }, [promptToken, timeoutId]);

  // useEffect(() => {
  //   if (promptSections.length > 0) {
  //     const promptResponses =
  //       document.getElementsByClassName("prompt-response");
  //     const dialogWrapper = document.getElementById("dialog-wrapper");
  //     if (promptResponses.length > 0) {
  //       const promptResponse = promptResponses[promptResponses.length - 1];
  //       dialogWrapper?.scrollTo({
  //         top: dialogWrapper.scrollHeight - promptResponse.clientHeight - 25,
  //         behavior: "smooth",
  //       });
  //     }
  //   }
  // }, [promptSections]);

  const onLogoClick = () => {
    history.push("/");
  };

  const onCardClick = (product: Product) => {
    onProductClick(product);
  };

  const onLikeCardClick = useCallback(
    (productId: string, productLink: string, callback: () => void) => {
      if (favoritesCount < 5) {
        setFavoritesCount((p) => p + 1);
        addLikedProduct(sessionId, userId, productId)
          .then((res) => {
            setLikedProducts(
              res.map((product) => {
                return {
                  name: product.name,
                  tags: [],
                  //product.highlights.split(",").map((highlight) => highlight.trimStart()),
                  rating: product.ratings,
                  thumbnail: product.image_links[0],
                  price: product.typical_prices
                    ? product.typical_prices.low_price
                    : "-",
                  id: product.product_id,
                  link: product.link,
                };
              })
            );
            setFavoritesCount(res.length);
            callback();
          })
          .catch(() => {
            setFavoritesCount((p) => p - 1);
          });
      }
    },
    [favoritesCount]
  );

  const onRemoveCardClick = (
    productId: string,
    productLink: string,
    callback: () => void
  ) => {
    removeLikedProducts(sessionId, userId, productId).then((res) => {
      setLikedProducts(
        res.map((product) => {
          return {
            name: product.name,
            tags: [],
            //product.highlights.split(",").map((highlight) => highlight.trimStart()),
            rating: product.ratings,
            thumbnail: product.image_links[0],
            price: product.typical_prices
              ? product.typical_prices.low_price
              : "-",
            id: product.product_id,
            link: product.link,
          };
        })
      );

      setFavoritesCount((p) => {
        return p - 1;
      });

      callback();
    });
  };

  const onTabClick = useCallback(
    (tab: Tab) => {
      setActiveTab(tab);
    },
    [activeTab]
  );

  return (
    <Wrapper>
      <Header>
        <img
          src={logo}
          alt=""
          width={128}
          height={26}
          style={{
            verticalAlign: "middle",
            paddingRight: 4,
            cursor: "pointer",
          }}
          onClick={onLogoClick}
        />
        <TabWrapper>
          <TabButton
            tab={Tab.Search}
            isActive={Tab.Search === activeTab}
            onClick={() => onTabClick(Tab.Search)}
          >
            <ChatIcon /> Chat
          </TabButton>
          <TabButton
            tab={Tab.Favorites}
            isActive={Tab.Favorites === activeTab}
            onClick={() => onTabClick(Tab.Favorites)}
          >
            <LikeIcon /> Favorites{` (${likedProducts.length.toString()}/5)`}
          </TabButton>
        </TabWrapper>
      </Header>
      <ContentWrapper>
        {isLoadingChatHistory ? (
          <LoadingChatHistoryWrapper>
            <LoadingChatHistoryQuestionPlaceholder />
            <LoadingChatHistoryResponsePlaceholderWrapper>
              <LoadingChatHistoryResponseIcon>
                <PromptLogo />
              </LoadingChatHistoryResponseIcon>
              <LoadingChatHistoryResponsePlaceholders>
                <LoadingChatHistoryResponsePlaceholder />
                <LoadingChatHistoryResponsePlaceholder />
                <LoadingChatHistoryResponsePlaceholder />
                <LoadingChatHistoryResponsePlaceholder />
              </LoadingChatHistoryResponsePlaceholders>
            </LoadingChatHistoryResponsePlaceholderWrapper>
          </LoadingChatHistoryWrapper>
        ) : (
          <Body>
            {activeTab === Tab.Search ? (
              <DialogWrapper id="dialog-wrapper">
                <>
                  {promptSections.map((promptSection, index) => (
                    <PromptSectionWrapper
                      key={index}
                      className={`${
                        promptSection.layoutScheme === "question"
                          ? "question-prompt-section"
                          : ""
                      }`}
                    >
                      <PromptSection
                        sectionContent={promptSection}
                        cls="prompt-response"
                        sessionId={sessionId}
                        onSuggestedQuestionClick={(query: string) => {
                          if (!isLoadingIntent) {
                            submitQuery(query);
                            setNeedscroll(true);
                          }
                        }}
                        onGeneralTextPrinted={() => {
                          setNeedscroll(true);
                        }}
                        onCardClick={onCardClick}
                        onLikeCardClick={onLikeCardClick}
                        onRemoveCardClick={onRemoveCardClick}
                        likedProducts={likedProducts}
                        favoritesCount={favoritesCount}
                      />
                    </PromptSectionWrapper>
                  ))}
                </>
              </DialogWrapper>
            ) : (
              <Favorites
                sessionId={sessionId}
                favorites={likedProducts}
              ></Favorites>
            )}
            {activeTab === Tab.Search ? (
              <DialogFooter>
                {isStopZButtonVisible && (
                  <StopButtonWrapper onClick={handleStopClick}>
                    Stop
                    <StopButtonSign />
                  </StopButtonWrapper>
                )}
                <InputWrapper>
                  <Input
                    autoFocus
                    type="text"
                    onKeyDown={handleKeyDown}
                    value={inputText}
                    onChange={handleInputChange}
                    placeholder="Ask questions to learn more"
                  />
                  <SendChatIconWrapper onClick={sendPrompt}>
                    <SendChatIcon />
                  </SendChatIconWrapper>
                </InputWrapper>
              </DialogFooter>
            ) : null}
          </Body>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Prompt;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  overflow: hidden;
  height: 100vh;
`;

const Header = styled.div`
  display: flex;
  height: 56px;
  padding: 12px 24px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const TabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

interface TabButtonProps extends React.AllHTMLAttributes<HTMLDivElement> {
  isActive: boolean;
  tab: Tab;
}
const TabButton = styled.div<TabButtonProps>`
  color: var(--purple-gray90, #43364c);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  display: flex;
  height: 12px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--purple-gray20, #e5e0e9);
  cursor: pointer;

  ${({ tab }) =>
    tab === Tab.Favorites
      ? `svg { width: 18px; height: 18px; }`
      : `svg { width: 16px; height: 16px; }`}

  path {
    stroke: #43364c;
  }

  ${(props) =>
    props.isActive
      ? `
        background: var(--brand, linear-gradient(97deg, #8319F5 4.5%, #C24087 127.01%));
        color: white;
        path {
          stroke: white;
          fill: white;
        }`
      : `background: var(--purple-gray10, #f7f6f9);`}

  &:hover {
    ${(props) => (props.isActive ? `opacity: 1;` : `opacity: 0.7;`)}
  }
`;

const LoadingChatHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  padding: 24px 40px;
  gap: 24px;
  height: 100vh;
`;

const LoadingChatHistoryQuestionPlaceholder = styled.div`
  width: 60%;
  align-self: flex-end;
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  border-radius: 6px;
  animation: ${fadeInOut} 2s infinite;
  background: linear-gradient(
    113deg,
    rgba(0, 0, 0, 0.06) -14.67%,
    rgba(0, 0, 0, 0.02) 54.92%,
    rgba(0, 0, 0, 0.06) 126.88%
  );
`;
const LoadingChatHistoryResponsePlaceholderWrapper = styled.div`
  display: flex;
  padding: 16px 0px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
const LoadingChatHistoryResponsePlaceholders = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
const LoadingChatHistoryResponsePlaceholder = styled.div`
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6.277px;
  align-self: stretch;
  border-radius: 6px;
  animation: ${fadeInOut} 2s infinite;
  background: linear-gradient(
    113deg,
    rgba(0, 0, 0, 0.06) -14.67%,
    rgba(0, 0, 0, 0.02) 54.92%,
    rgba(0, 0, 0, 0.06) 126.88%
  );

  &:last-of-type {
    width: 64%;
  }
`;

const LoadingChatHistoryResponseIcon = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 110.341px;
  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  padding: 0 24px;
  overflow: hidden;
  height: 100%;
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const DialogWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  overflow: auto;
  width: 100%;
  height: calc(100% - 86px);
  overflow: auto;
`;

const DialogFooter = styled.div`
  display: flex;
  padding: 16px 40px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  position: fixed;
  bottom: 0;
  justify-self: anchor-center;
  width: 100%;
  background: #f7f6f9;
`;

const InputWrapper = styled.div`
  display: flex;
  height: 54px;
  width: 720px;
  padding: 0px 12px 0px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 200px;
  background: var(--white, #fff);
  box-shadow: 0px 4px 20px 12px rgba(0, 0, 0, 0.03);
`;

const Input = styled.input<React.AllHTMLAttributes<HTMLInputElement>>`
  width: 100%;
  color: var(--gray70, #74697b);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  border: unset;

  &:focus {
    border: unset;
    outline: none;
  }
`;

const SendChatIconWrapper = styled.div<
  React.AllHTMLAttributes<HTMLInputElement>
>`
  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
  border-radius: 50%;
  height: 30px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  svg {
    fill: white;
    height: 18px;
    width: 18px;
  }
`;

const PromptSectionWrapper = styled.div<
  React.AllHTMLAttributes<HTMLInputElement>
>`
  width: 100%;
`;

const StopButtonWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  width: 70px;
  height: 30px;
  background-color: var(--purple-gray15, #efecf2);
  color: #74697b;
  transform: translateY(-50px);
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
`;

const StopButtonSign = styled.div`
  width: 8px;
  height: 8px;
  background-color: #74697b;
  border-radius: 2px;
`;
