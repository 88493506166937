import React from "react";
import styled from "styled-components";

import { ProductDetails } from "./ProductDetails";
import { Product } from "../models";

interface ProductPanelProps {
  sessionId: string;
  selectedProduct: Product;
}

export const ProductPanel: React.FC<ProductPanelProps> = ({
  sessionId,
  selectedProduct,
}) => {
  //const onLikeCardClick = useCallback(
  //  (productId: string, productLink: string, callback: () => void) => {
  //    addLikedProduct(sessionId, userId, productId).then((res) => {
  //      setLikedProducts(
  //        res.map((product) => {
  //          return {
  //            name: product.name,
  //            tags: [],
  //            //product.highlights.split(",").map((highlight) => highlight.trimStart()),
  //            rating: product.ratings,
  //            thumbnail: product.image_links[0],
  //            price: product.typical_prices
  //              ? product.typical_prices.low_price
  //              : "-",
  //            id: product.product_id,
  //            link: product.link,
  //          };
  //        })
  //      );
  //      callback();
  //    });
  //  },
  //  [setLikedProducts]
  //);
  //
  //const onRemoveCardClick = useCallback(
  //  (productId: string, productLink: string, callback: () => void) => {
  //    removeLikedProducts(sessionId, userId, productId).then((res) => {
  //      setLikedProducts(
  //        res.map((product) => {
  //          return {
  //            name: product.name,
  //            tags: [],
  //            //product.highlights.split(",").map((highlight) => highlight.trimStart()),
  //            rating: product.ratings,
  //            thumbnail: product.image_links[0],
  //            price: product.typical_prices
  //              ? product.typical_prices.low_price
  //              : "-",
  //            id: product.product_id,
  //            link: product.link,
  //          };
  //        })
  //      );
  //      callback();
  //    });
  //  },
  //  [setLikedProducts]
  //);

  return (
    <Wrapper>
      <Body>
        <Content>
          <ProductDetails product={selectedProduct} sessionId={sessionId} />
        </Content>
      </Body>
    </Wrapper>
  );
};

ProductPanel.displayName = "ProductPanel";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  border: 1px solid var(--purple-gray20, #e5e0e9);
  box-shadow: 2px 2px 8px 8px rgba(67, 54, 76, 0.24);
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: var(--white, #fff);
  padding: 48px 36px 22px 36px;
  width: 616px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`;
