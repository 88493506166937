import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Rating from "react-rating";
import { truncate, isEmpty } from "lodash";

import { StarFilled, StarGray } from "components/icon/Star";
import { Section } from "./Section";
import { Product } from "../models";
import {
  getBuyingOptionV2,
  getOverviewV2,
  getReviewV2,
  getSpecificationV2,
} from "services/product";
import { BuyingOption, Review, Specification, Overview } from "./models";
import { ReviewSection } from "./ReviewSection";
import { SpecificationsSection } from "./SpecificationsSection";
import { BuyingOptionsSection } from "./BuyingOptionsSection";
import {
  GetBuyingOptionResponseV2,
  GetOverviewResponseV2,
  GetReviewsResponseV2,
  GetSepecificationsResponseV2,
} from "models/services/api";
import { usePollingV2 } from "hooks/polling";

interface Props {
  product: Product;
  sessionId: string;
}

export const ProductDetails: React.FC<Props> = ({ product, sessionId }) => {
  const [review, setReview] = useState<Review | undefined>(undefined);
  const [buyingOptions, setBuyingOptions] = useState<BuyingOption[]>([]);
  const [specifications, setSpecifications] = useState<
    Specification | undefined
  >(undefined);
  const [overview, setOverview] = useState<Overview | undefined>(undefined);

  const [isLoadingReview, setIsLoadingReview] = useState<boolean>(true);
  const [isLoadingBuyingOptions, setIsLoadingBuyingOptions] =
    useState<boolean>(true);
  const [isLoadingSpecifications, setIsLoadingSpecifications] =
    useState<boolean>(true);
  const [isLoadingOverview, setIsLoadingOverview] = useState<boolean>(true);

  const [shouldPollReviews, setShouldPollReviews] = useState<boolean>(false);
  const [shouldPollBuyingOptions, setShouldPollBuyingOptions] =
    useState<boolean>(false);
  const [shouldPollSpecifications, setShouldPollSpecifications] =
    useState<boolean>(false);
  const [shouldPollOverview, setShouldPollOverview] = useState<boolean>(false);

  const { data: reviewsResponse, intervalId: reviewsIntervalId } =
    usePollingV2<GetReviewsResponseV2>(
      getReviewV2,
      shouldPollReviews,
      [sessionId, product.id],
      5000
    );
  const { data: buyingOptionsResponse } =
    usePollingV2<GetBuyingOptionResponseV2>(
      getBuyingOptionV2,
      shouldPollBuyingOptions,
      [sessionId, product.id],
      5000
    );
  const { data: specificationsResponse } =
    usePollingV2<GetSepecificationsResponseV2>(
      getSpecificationV2,
      shouldPollSpecifications,
      [sessionId, product.id],
      5000
    );

  const { data: overviewResponse } = usePollingV2<GetOverviewResponseV2>(
    getOverviewV2,
    shouldPollOverview,
    [sessionId, product.id],
    5000
  );

  useEffect(() => {
    return () => {
      clearInterval(reviewsIntervalId);
    };
  }, []);

  useEffect(() => {
    if (product.id && sessionId) {
      setShouldPollReviews(true);
      setShouldPollBuyingOptions(true);
      setShouldPollSpecifications(true);
      setShouldPollOverview(true);
    }
  }, [product, sessionId]);

  useEffect(() => {
    if (
      !isEmpty(reviewsResponse) &&
      (!isEmpty(reviewsResponse.histogram) || reviewsResponse.textcontent)
    ) {
      setReview(reviewsResponse);
      setIsLoadingReview(false);
      setShouldPollReviews(
        (isEmpty(reviewsResponse.histogram) && reviewsResponse.count !== 0) ||
          !reviewsResponse.textcontent
      );
    }
  }, [reviewsResponse]);

  useEffect(() => {
    if (
      buyingOptionsResponse &&
      !isEmpty(buyingOptionsResponse.buying_options)
    ) {
      setBuyingOptions(buyingOptionsResponse.buying_options);
      setIsLoadingBuyingOptions(false);
      setShouldPollBuyingOptions(false);
    }
  }, [buyingOptionsResponse]);

  useEffect(() => {
    if (
      !isEmpty(specificationsResponse) &&
      (specificationsResponse.textcontent ||
        (!isEmpty(specificationsResponse.original_content) &&
          !isEmpty(specificationsResponse.original_content.list)))
    ) {
      setSpecifications(specificationsResponse);
      setIsLoadingSpecifications(!specificationsResponse.textcontent);
      setShouldPollSpecifications(!specificationsResponse.textcontent);
    }
  }, [specificationsResponse]);

  useEffect(() => {
    if (!isEmpty(overviewResponse) && overviewResponse.textcontent) {
      setOverview(overviewResponse);
      setIsLoadingOverview(false);
      setShouldPollOverview(false);
    }
  }, [overviewResponse]);

  return (
    <Wrapper>
      <Content>
        <Header>
          <Thumbnail backgroundImage={product.thumbnail}></Thumbnail>
          <Summary>
            <Title>
              <Name>
                {truncate(product.name, { length: 80, omission: "..." })}
              </Name>
              <RatingAndPrice>
                <RatingWrapper>
                  <Stars>
                    <Rating
                      initialRating={product.rating}
                      readonly
                      emptySymbol={<StarGray />}
                      fullSymbol={<StarFilled />}
                    />
                  </Stars>
                </RatingWrapper>
                <PriceWrapper>
                  <From>About</From>
                  <Price>{product.price}</Price>
                </PriceWrapper>
              </RatingAndPrice>
            </Title>
            <></>
            {overview ? (
              <Tags>
                {overview.highlights.map((highlight, index) => (
                  <Tag key={index}>{highlight}</Tag>
                ))}
              </Tags>
            ) : null}
          </Summary>
        </Header>
        <Body>
          <Details>
            <Section
              isLoading={isLoadingOverview}
              name="Overview"
              content={
                <OverviewWrapper>
                  <OverviewText>{overview?.textcontent}</OverviewText>
                </OverviewWrapper>
              }
            />
            <Splitter />
            <Section
              isLoading={isLoadingBuyingOptions}
              name="Buying Options"
              count={buyingOptions.length}
              content={<BuyingOptionsSection buyingOptions={buyingOptions} />}
            />
            <Splitter />
            <Section
              isLoading={isLoadingSpecifications}
              name="Specifications"
              loadingText={
                specificationsResponse &&
                !isEmpty(specificationsResponse.original_content.list) &&
                !specificationsResponse.textcontent
                  ? "Summarizing..."
                  : undefined
              }
              content={
                <SpecificationsSection specifications={specifications} />
              }
            />
            <Splitter />
            <Section
              isLoading={isLoadingReview}
              name="Ratings and Reviews"
              content={<ReviewSection review={review} />}
            />
          </Details>
        </Body>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  overflow: scroll;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

const Thumbnail = styled.div<{ backgroundImage: string }>`
  display: flex;
  width: 120px;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6.277px;
  border-radius: 16px;
  border: 1.4px solid var(--purple-gray20, #e5e0e9);
  background: #fff;
  background-image: url(${(props) => props.backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

const RatingAndPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Name = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 6px;
`;

const From = styled.div`
  color: var(--purple-gray60, #83768b);
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  color: var(--purple-gray90, #83768b);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const Tags = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 16px;
`;

const Tag = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  height: 22px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 3.229px;
  border-radius: 19px;
  border: 1px solid var(--purple-gray20, #e5e0e9);
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  padding-bottom: 35px;
`;

const OverviewText = styled.div`
  color: var(--purple-gray90, #43364c);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  align-self: stretch;
`;

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 24px;
`;

const Stars = styled.div`
  display: flex;
  align-items: center;
  gap: 1.776px;

  span {
    height: 16px;
  }

  svg {
    height: 16px;
    width: 16px;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const Splitter = styled.div`
  background: #f7f6f9;
  height: 1px;
  width: 100%;
`;
