import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Rating from "react-rating";
import { truncate } from "lodash";
import Markdown from "react-markdown";

import { StarFilled, StarGray } from "components/icon/Star";
import LikeIcon from "components/icon/LikeIcon";
import UnlikeIcon from "components/icon/UnlikeIcon";
import { RemoveIcon } from "components/icon/RemoveIcon";
import { Product } from "../models";
import loadingspinner from "assets/images/loadingspinner.gif";
import AIIcon from "components/icon/AIIcon";

interface CardProps {
  product: Product;
  shouldShowLike?: boolean;
  shouldShowRemove?: boolean;
  isLiked?: boolean;
  isRecommended?: boolean;
  canLikeMore: boolean;
  onClick: () => void;
  onLikeClick?: (
    productId: string,
    productLink: string,
    callback: () => void
  ) => void;
  onRemoveClick?: (
    productId: string,
    productLink: string,
    callback: () => void
  ) => void;
}

export const Card: React.FC<CardProps> = ({
  product,
  shouldShowLike = false,
  shouldShowRemove = false,
  isLiked = false,
  isRecommended = false,
  canLikeMore,
  onClick,
  onLikeClick,
  onRemoveClick,
}) => {
  const [isLikeLoading, setIsLikeLoading] = useState<boolean>(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  useEffect(() => {
    return () => setIsMounted(false);
  }, []);

  const onLikeClickWrapper = useCallback(
    (event: React.MouseEvent, productId: string, productLink: string) => {
      if (onLikeClick && !isLikeLoading) {
        if (canLikeMore) {
          setIsLikeLoading(true);
        }
        onLikeClick(productId, productLink, () => {
          if (isMounted) {
            setIsLikeLoading(false);
          }
        });
        event.stopPropagation();
      }
    },
    [onLikeClick, canLikeMore, isLikeLoading]
  );

  const onRemoveClickWrapper = useCallback(
    (event: React.MouseEvent, productId: string, productLink: string) => {
      if (onRemoveClick && !isRemoveLoading) {
        setIsRemoveLoading(true);

        onRemoveClick(productId, productLink, () => {
          if (isMounted) {
            setIsRemoveLoading(false);
          }
        });
        event.stopPropagation();
      }
    },
    [onRemoveClick, isRemoveLoading]
  );

  return (
    <CardWrapper isRecommended={isRecommended} onClick={onClick}>
      <ThumbnailWrapper isRecommended={isRecommended}>
        <Thumbnail
          className="thumbnail"
          backgroundImage={product.thumbnail}
          isRecommended={isRecommended}
        ></Thumbnail>
      </ThumbnailWrapper>
      <DetailsWrapper isRecommended={isRecommended}>
        <DetailsContent>
          <InfoWrapper>
            <Info>
              <ProductName isRecommended={isRecommended}>
                {truncate(product.name, { length: 60 })}
              </ProductName>
              <Extra>
                <RatingWrapper>
                  <Rating
                    initialRating={product.rating}
                    readonly
                    emptySymbol={<StarGray />}
                    fullSymbol={<StarFilled />}
                  />
                </RatingWrapper>
                <Price>About {product.price}</Price>
              </Extra>
            </Info>
          </InfoWrapper>
          <Ctas>
            {shouldShowLike && (
              <Like
                onClick={(e) => onLikeClickWrapper(e, product.id, product.link)}
              >
                {isLikeLoading ? (
                  <LoadingSpinnerWrapper src={loadingspinner} />
                ) : isLiked ? (
                  <UnlikeIcon />
                ) : (
                  <LikeIcon />
                )}
              </Like>
            )}
            {shouldShowRemove && (
              <Remove
                onClick={(e) =>
                  onRemoveClickWrapper(e, product.id, product.link)
                }
              >
                {isRemoveLoading ? (
                  <LoadingSpinnerWrapper src={loadingspinner} />
                ) : (
                  <RemoveIcon />
                )}
              </Remove>
            )}
          </Ctas>
        </DetailsContent>
        <TagWrapper>
          {product.tags.slice(0, 3).map((tag, index) => (
            <ProductTag key={index}>{tag}</ProductTag>
          ))}
        </TagWrapper>
        {isRecommended && (
          <Reason>
            {product.buyReason && <Markdown>{product.buyReason}</Markdown>}
          </Reason>
        )}
      </DetailsWrapper>
      {isRecommended && (
        <RecommendedLabel>
          <AIIconWrapper>
            <AIIcon />
          </AIIconWrapper>
          Top Pick
        </RecommendedLabel>
      )}
    </CardWrapper>
  );
};

interface CardWrapperProps extends React.AllHTMLAttributes<HTMLDivElement> {
  isRecommended: boolean;
}

const CardWrapper = styled.div<CardWrapperProps>`
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: white;
  box-sizing: border-box;
  cursor: pointer;
  flex-direction: column;
  position: relative;
  justify-content: center;
  gap: 6px;
  max-width: 402px;

  ${(props) =>
    props.isRecommended ? `border: 1.4px solid var(--brand, #8319F5);` : ``}

  &:hover {
    .thumbnail {
      transform: scale(1.1);
    }
  }

  &:nth-child(odd) {
    grid-row: 1;
  }

  &:nth-child(even) {
    grid-row: 2;
  }
`;

const ThumbnailWrapper = styled.div<CardWrapperProps>`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 10px 12px 0;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  ${(props) => (props.isRecommended ? `width: 376px;` : `width: 196px;`)}
`;

interface ThumbnailProps extends React.AllHTMLAttributes<HTMLDivElement> {
  backgroundImage: string;
  isRecommended: boolean;
}

const Thumbnail = styled.div<ThumbnailProps>`
  ${(props) =>
    props.isRecommended
      ? `width: 200px; height: 200px;`
      : `width: 140px; height: 140px;`}

  flex-shrink: 0;
  background-image: url(${(props) => props.backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  outline: none;
  transition: transform 0.3s ease;
`;

const DetailsWrapper = styled.div<CardWrapperProps>`
  display: flex;
  padding: 0 16px 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  ${(props) => (props.isRecommended ? `` : `width: 240px`)}
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const ProductName = styled.div<CardWrapperProps>`
  align-self: stretch;
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-style: normal;

  ${(props) => (props.isRecommended ? `font-size: 16px;` : `font-size: 14px;`)}
`;

const Extra = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 12px;
`;

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.776px;
  height: 18px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--purple-gray60, #83768b);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`;

const Ctas = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const Like = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  background: white;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 #0000001f;
  padding: 4px;

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
`;

const Remove = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  background: white;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 #0000001f;
  padding: 4px;

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
`;

const DetailsContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-direction: column;
`;

const TagWrapper = styled.div`
  display: flex;
  padding-right: 16px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px 6px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const ProductTag = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  height: 22px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 3.229px;
  border-radius: 19px;
  border: 1px solid var(--purple-gray20, #e5e0e9);
  color: var(--purple-gray70, #74697b);
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const LoadingSpinnerWrapper = styled.img<
  React.AllHTMLAttributes<HTMLImageElement>
>`
  width: 16px;
  height: 16px;
`;

const RecommendedLabel = styled.div`
  display: flex;
  height: 26px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: -13px;
  border-radius: 16px;
  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
  color: var(--white, #fff);
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const AIIconWrapper = styled.div`
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Reason = styled.div``;
