import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import { isEmpty } from "lodash";
import StarRatings from "react-star-ratings";
import loadingspinner from "assets/images/loadingspinner.gif";

import { Review } from "./models";

interface ReviewSectionProps {
  review?: Review;
}

export const ReviewSection: React.FC<ReviewSectionProps> = ({ review }) => {
  return (
    <Wrapper>
      {!isEmpty(review) && (
        <>
          {review.count !== null &&
            review.count !== 0 &&
            !isEmpty(review.histogram) && (
              <SummaryWrapper>
                <Summary>
                  <OverviewWrapper>
                    <Score>{review.rating}</Score>
                    <StarRatingsWrapper>
                      <StarRatings
                        numberOfStars={5}
                        rating={review.rating}
                        starDimension="12px"
                        starRatedColor="rgb(222, 121, 33)"
                        starSpacing="1px"
                        svgIconViewBox="0 0 24 24"
                        svgIconPath="M12.7056 1.75164C12.573 1.48304 12.2995 1.31299 11.9999 1.31299C11.7004 1.31299 11.4268 1.48304 11.2942 1.75164L8.38714 7.64104L1.88609 8.59126C1.58974 8.63458 1.34366 8.84235 1.25128 9.12725C1.15891 9.41215 1.23625 9.72479 1.4508 9.93376L6.15427 14.5149L5.04424 20.987C4.9936 21.2822 5.11499 21.5806 5.35737 21.7567C5.59974 21.9328 5.92107 21.956 6.18622 21.8165L11.9999 18.7592L17.8136 21.8165C18.0788 21.956 18.4001 21.9328 18.6425 21.7567C18.8848 21.5806 19.0062 21.2822 18.9556 20.987L17.8456 14.5149L22.549 9.93376C22.7636 9.72479 22.8409 9.41215 22.7485 9.12725C22.6562 8.84235 22.4101 8.63458 22.1137 8.59126L15.6127 7.64104L12.7056 1.75164Z"
                      />
                    </StarRatingsWrapper>
                    <ReviewCount>{review.count}</ReviewCount>
                    <ReviewCount>reviews</ReviewCount>
                  </OverviewWrapper>
                  <RatingDetails>
                    <RatingDetail>
                      <RatingNumber>5 starts</RatingNumber>
                      <RatingBarWrapper>
                        {review.histogram && review.count && (
                          <RatingBar
                            percentage={`${String(
                              (review.histogram["5"] / review.count) * 100
                            )}%`}
                          />
                        )}
                      </RatingBarWrapper>
                    </RatingDetail>
                    <RatingDetail>
                      <RatingNumber>4 starts</RatingNumber>
                      <RatingBarWrapper>
                        {review.histogram && review.count && (
                          <RatingBar
                            percentage={`${String(
                              (review.histogram["4"] / review.count) * 100
                            )}%`}
                          />
                        )}
                      </RatingBarWrapper>
                    </RatingDetail>
                    <RatingDetail>
                      <RatingNumber>3 starts</RatingNumber>
                      <RatingBarWrapper>
                        {review.histogram && review.count && (
                          <RatingBar
                            percentage={`${String(
                              (review.histogram["3"] / review.count) * 100
                            )}%`}
                          />
                        )}
                      </RatingBarWrapper>
                    </RatingDetail>
                    <RatingDetail>
                      <RatingNumber>2 starts</RatingNumber>
                      <RatingBarWrapper>
                        {review.histogram && review.count && (
                          <RatingBar
                            percentage={`${String(
                              (review.histogram["2"] / review.count) * 100
                            )}%`}
                          />
                        )}
                      </RatingBarWrapper>
                    </RatingDetail>
                    <RatingDetail>
                      <RatingNumber>1 starts</RatingNumber>
                      <RatingBarWrapper>
                        {review.histogram && review.count && (
                          <RatingBar
                            percentage={`${String(
                              (review.histogram["1"] / review.count) * 100
                            )}%`}
                          />
                        )}
                      </RatingBarWrapper>
                    </RatingDetail>
                  </RatingDetails>
                </Summary>
              </SummaryWrapper>
            )}
          {review.filters && (
            <Tags>
              {review.filters.slice(0, 5).map((filter, index) => (
                <Tag key={index}>{`${filter.title} (${filter.reviews})`}</Tag>
              ))}
            </Tags>
          )}
          {review.textcontent ? (
            <Content
              isNoReview={review.textcontent.length > 0 && review.count === 0}
            >
              <Markdown>{review.textcontent}</Markdown>
            </Content>
          ) : (
            <LoadingReviewDetails>
              <LoadingReviewIcon src={loadingspinner} />
              <LoadingReviewText>Summarizing reviews...</LoadingReviewText>
            </LoadingReviewDetails>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const Summary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
`;

const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
`;

const Score = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
`;

const ReviewCount = styled.div`
  font-size: 14px;
  color: var(--purple-gray90, #83768b);
  font-family: "Noto Sans";

  &:last-of-type {
    margin-top: -4px;
  }
`;

const StarRatingsWrapper = styled.div`
  .star {
    border-radius: 10%;
  }
`;

const RatingDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex: 1;
  gap: 4px;
`;

const RatingDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
`;

const RatingNumber = styled.div`
  width: 60px;
  color: var(--purple-gray90, #43364c);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

const RatingBarWrapper = styled.div`
  height: 16px;
  flex: 1;
  margin: 0 8px;
  box-shadow: inset 0 0 0 1px #888c8c;
  border-radius: 5px;
`;
interface RatingBarProps extends React.AllHTMLAttributes<HTMLDivElement> {
  percentage: string;
}
const RatingBar = styled.div<RatingBarProps>`
  height: 100%;
  width: ${(props) => props.percentage};
  background-color: #de7921;
  border-radius: 5px;
`;

const Tags = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px 6px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const Tag = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 200px;
  background: var(--transparent20, rgba(0, 0, 0, 0.04));
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--black, #0d0319);
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

interface ContentProps extends React.AllHTMLAttributes<HTMLDivElement> {
  isNoReview: boolean;
}
const Content = styled.div<ContentProps>`
  align-self: stretch;
  color: ${(props) =>
    props.isNoReview
      ? `var(--gray70,#74697b);`
      : `var(--purple-gray90, #43364c);`};

  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: ${(props) => (props.isNoReview ? "-48px" : "0px")};

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 0;
  }
`;

const LoadingReviewDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  gap: 8px;
`;

const LoadingReviewText = styled.div`
  color: var(--purple-gray70, #74697b);
  text-align: center;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const LoadingReviewIcon = styled.img<React.AllHTMLAttributes<HTMLImageElement>>`
  width: 20px;
  height: 20px;
`;
