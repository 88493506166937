import styled from "styled-components";
import React, { ReactNode, useCallback, useState } from "react";

import ChevronUpIcon from "components/icon/ChevronUpIcon";
import { SectionName } from "services/product";

interface ProductCardProps {
  title: string;
  button?: ReactNode;
  size: number;
  sessionId: string;
  sectionName: SectionName | string;
  id: string;
}

const ProductSection: React.FC<ProductCardProps> = ({
  title,
  button,
  children,
  id,
}) => {
  const [disableContent, setDisableContent] = useState<boolean>(false);

  const toggleContent = useCallback(() => {
    if (disableContent) {
      setDisableContent(false);
    } else {
      setDisableContent(true);
    }
  }, [disableContent, setDisableContent]);

  return (
    <StyledWrapper id={id}>
      <BarWrapper>
        <NavWrapper>
          <IconWrapper onClick={toggleContent}>
            <ChevronUpIconWrapper flipped={!disableContent}>
              <ChevronUpIcon />
            </ChevronUpIconWrapper>
          </IconWrapper>
          <Title>{title}</Title>
        </NavWrapper>
        <PerformanceButtonWrapper>{button || button}</PerformanceButtonWrapper>
      </BarWrapper>
      {disableContent || <ContentWrapper>{children}</ContentWrapper>}
    </StyledWrapper>
  );
};

export default ProductSection;

const StyledWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  flex-wrap: wrap;

  &:last-of-type {
    margin-bottom: 36px;
  }
`;

const BarWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NavWrapper = styled("div")`
  display: flex;
  flex-direction: row;
`;

const PerformanceButtonWrapper = styled("div")``;

interface ChevronUpIconWrapper extends React.AllHTMLAttributes<HTMLDivElement> {
  flipped: boolean;
}

const ChevronUpIconWrapper = styled.div<ChevronUpIconWrapper>`
  height: 26px;
  width: 26px;
  transition: transform 0.3s ease; /* Smooth transition */
  transform-origin: center;
  transform: ${({ flipped }) => (flipped ? "rotate(180deg)" : "rotate(0deg)")};
`;

const IconWrapper = styled("div")`
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 26px;
    height: 26px;
  }
`;

const ContentWrapper = styled("div")`
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
`;

const Title = styled.div`
  user-select: none;
  cursor: default;
  color: #0d0319;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 41px;
`;
