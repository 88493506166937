import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { NavBar } from "./NavBar";
import { AppContext } from "context/AppContext";
import { getChatList } from "services/chat";
import { Chat } from "./NavBar";
import { createUser } from "services/user";

interface BaseLayoutProps {
  mainComponent: React.ReactNode;
  floatingComponent?: React.ReactNode;
  isFloatingComponentVisible: boolean;
  onClickOverlay?: () => void;
}

export const BaseLayout: React.FC<BaseLayoutProps> = ({
  mainComponent,
  floatingComponent,
  isFloatingComponentVisible = false,
  onClickOverlay,
}) => {
  const {
    state: { userId, userName },
    dispatch,
  } = useContext(AppContext);
  const [chatList, setChatList] = useState<Chat[]>([]);
  const history = useHistory();

  const onSelectChat = useCallback(
    (index) => {
      history.push(`/chat/${chatList[index].id}`);
    },
    [chatList]
  );

  useEffect(() => {
    if (userId && userId !== "null") {
      getChatList(userId).then((res) => {
        setChatList(
          res.map((chat) => ({
            aiTitle: chat.ai_title,
            createdAt: chat.created_at,
            id: chat.id,
            title: chat.title,
            userId: chat.user_id,
          }))
        );
      });
    }
  }, [userId, setChatList]);

  useEffect(() => {
    if (!userId || userId === "null") {
      dispatch({ type: "CLEAR_SESSION_ID" });

      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          createUser(userName, data.ip).then((res) => {
            dispatch({ type: "SET_USER_ID", payload: res.id });
          });
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  }, []);

  return (
    <BaseLayoutWrapper>
      {/*<NavBar chatList={chatList} onSelectChat={onSelectChat}></NavBar>*/}
      {userId && (
        <ContentWrapper>
          <MainComponentWrapper>{mainComponent}</MainComponentWrapper>
          {isFloatingComponentVisible && (
            <Overlay onClick={onClickOverlay}></Overlay>
          )}
          {floatingComponent ? (
            <FloatingComponentWrapper isVisible={isFloatingComponentVisible}>
              {floatingComponent}
            </FloatingComponentWrapper>
          ) : null}
        </ContentWrapper>
      )}
    </BaseLayoutWrapper>
  );
};

const BaseLayoutWrapper = styled.div`
  width: 100%;
  position: relative;
  background: var(--purple-gray15, #f7f6f9);
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const MainComponentWrapper = styled.div`
  width: 100%;
`;

const Overlay = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.6;
  overflow: hidden;
`;

interface FloatingComponentWrapperProps
  extends React.AllHTMLAttributes<HTMLDivElement> {
  isVisible: boolean;
}

const FloatingComponentWrapper = styled.div<FloatingComponentWrapperProps>`
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.2s ease;
  ${(props) =>
    props.isVisible
      ? `transform: translateX(0);`
      : `transform: translateX(100%);`}
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  z-index: 999;
  height: 100%;
`;
